import React from 'react';
export const Banner01DataSource = {
  wrapper: { className: 'banner0' },
  textWrapper: { className: 'banner0-text-wrapper lfgcvzensj-editor_css' },
  title: {
    className: 'banner0-title lfgcvwsyr6n-editor_css',
    children: 'https://coldchain.uzaiuzhai.com/coldchainimage/logo.png',
  },
  content: {
    className: 'banner0-content lfgcw2giztd-editor_css',
    children: '一个高效的页面动画解决方案',
  },
  button: {
    className: 'banner0-button lfgcwb1vvl-editor_css',
    children: 'Learn More',
  },
};
export const Content41DataSource = {
  wrapper: {
    className: 'home-page-wrapper content4-wrapper lffw6kyewr9-editor_css',
  },
  page: { className: 'home-page content4' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <p>
                    宏达冷链<span>提供专业的服务</span>
                  </p>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'title-h1',
      },
      {
        name: 'content',
        className: 'title-content content4-title-content lffw6sfpnb-editor_css',
        children: (
          <span>
            <span>
              <p>
                广州市宏达冷藏供应链服务有限公司位于广州市黄埔区宝丰路2号，冷链物流园占地面积3.7万平方米，库体占地1.7万平方米，总库容高达5万吨。
              </p>
              <p>
                全温区覆盖（冷藏/冷冻/恒温/常温），配套低温冷冻间、中央厨房预制菜车间、中温冷藏仓、高温蔬果仓、干仓、恒温仓、速冻间；
              </p>
              <p>
                宏达冷链物流中心集商务、办公、住宿一体化，有完善园区配套，提供分拣加工、供应链金融等定制化服务，可灵活满足各类用户需求，是冷链枢纽转运、连锁商超城配、餐饮DC大仓等业务的不二之选，为各类生鲜冷链物流、农产品专业交易市场等提供优质仓储、物流配送服务。
              </p>
            </span>
          </span>
        ),
      },
    ],
  },
  video: {
    className: 'content4-video',
    children: {
      video: 'http://qiniu.hdcoldchain.com/qqq.mp4',
      image: 'https://coldchain.uzaiuzhai.com/coldchainimage/5.png',
    },
  },
};
export const Banner51DataSource = {
  wrapper: { className: 'home-page-wrapper banner5' },
  page: { className: 'home-page banner5-page lffql6nnwdc-editor_css' },
  childWrapper: {
    className: 'banner5-title-wrapper lffqkzdp44e-editor_css',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>园区位置</p>
          </span>
        ),
        className: 'banner5-title',
      },
      {
        name: 'explain',
        className: 'banner5-explain',
        children: (
          <span>
            <p>粤港澳大湾区中心区域</p>
          </span>
        ),
      },
      {
        name: 'content',
        className: 'banner5-content',
        children: (
          <span>
            <p>
              周边高速路网密集，毗邻多条高速线路。东邻广州增城、东莞，南面与东莞麻涌仅一江之隔，西邻广州城市中心区及广州番禺区，北邻广州经济开发区东区、科学城、知识城，可辐射广州、东莞、深圳、惠州，交通便捷，可覆盖珠三角地区的物流服务。
            </p>
          </span>
        ),
      },
      {
        name: 'button',
        className: 'banner5-button-wrapper',
        children: {
          href: '#',
          className: 'banner5-button lffpz1duawo-editor_css',
          type: 'primary',
          children: '开始使用',
        },
      },
    ],
  },
  image: {
    className: 'banner5-image lffpyyri8dj-editor_css',
    children: 'https://coldchain.uzaiuzhai.com/coldchainimage/1.png',
  },
};
export const Feature30DataSource = {
  wrapper: {
    className: 'home-page-wrapper content3-wrapper lffoktl8kz9-editor_css',
  },
  page: { className: 'home-page content3' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>园区服务</p>
          </span>
        ),
        className: 'title-h1',
      },
    ],
  },
  block: {
    className: 'content3-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://coldchain.uzaiuzhai.com/coldchainimage/icon/snowflake.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <span>
                    <p>园区功能</p>
                  </span>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <span>
                    <p>全温区覆盖（冷藏/冷冻/恒温/常温），可灵活满足客户需求</p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block1',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://coldchain.uzaiuzhai.com/coldchainimage/icon/factory-building.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>配套完善</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>近10000平方米办公楼综合楼，办公/研发/展销直播配套完善；</p>
                <p>
                  办公/食堂/宿舍一样俱全，享受广州区域配套-东莞价格优惠，有利招工
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block2',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://coldchain.uzaiuzhai.com/coldchainimage/icon/map-road.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>高效周转</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  高周转：配有全封闭低温穿堂（月台），温度0-10℃可控，月台深度达14米，内部净高6米，双月台极速出货；
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block3',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://coldchain.uzaiuzhai.com/coldchainimage/icon/setting-config.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>适配度高</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <p>
                  一期16个装卸口，装卸口高度为1.2米，全部配有充电桩、高度调节板和耐磨机械门罩，能适用各类车型
                </p>
              </span>
            ),
          },
        },
      },
      {
        name: 'block4',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://coldchain.uzaiuzhai.com/coldchainimage/icon/financing.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>供应链金融</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>供应链金融配套服务，提供资金便利，灵活周转</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block5',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://coldchain.uzaiuzhai.com/coldchainimage/icon/truck.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>停车方便</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <span>
                    <p>
                      装卸广场约20000平方米，货柜车辆进出操作方便，并预留多个停车位，装卸便利
                    </p>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~lffu0lrjpv7',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://coldchain.uzaiuzhai.com/coldchainimage/icon/shop.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <p>商住配套便利</p>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>
                        距离南岗万达广场/华润万家/嘉荣超市/东兴汇批发市场800米
                      </p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~lffu0m91njc',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://coldchain.uzaiuzhai.com/coldchainimage/icon/link-cloud.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>产业集群</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>
                        与广州普洛斯宝普物流园、京东华南（广州）电子商务基地相邻，东莞江南农批等产业集群共享成熟资源
                      </p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
      {
        name: 'block~lffu0mpxxh',
        className: 'content3-block',
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: 'content3-icon',
            children:
              'https://coldchain.uzaiuzhai.com/coldchainimage/icon/holding-hands.png',
          },
          textWrapper: { className: 'content3-text' },
          title: {
            className: 'content3-title',
            children: (
              <span>
                <span>
                  <p>合作</p>
                </span>
              </span>
            ),
          },
          content: {
            className: 'content3-content',
            children: (
              <span>
                <span>
                  <span>
                    <span>
                      <p>可按板或按面积灵活租用，根据客户业务模式个性定制</p>
                    </span>
                  </span>
                </span>
              </span>
            ),
          },
        },
      },
    ],
  },
};
export const Feature70DataSource = {
  wrapper: {
    className: 'home-page-wrapper feature7-wrapper lffokpmtm0m-editor_css',
  },
  page: { className: 'home-page feature7 lffop63l15g-editor_css' },
  OverPack: { playScale: 0.3 },
  titleWrapper: {
    className: 'feature7-title-wrapper',
    children: [
      {
        name: 'title',
        className: 'feature7-title-h1',
        children: (
          <span>
            <span>
              <p>技术参数一览</p>
            </span>
          </span>
        ),
      },
    ],
  },
  blockWrapper: {
    className: 'feature7-block-wrapper',
    gutter: 24,
    children: [
      {
        md: 6,
        xs: 24,
        name: 'block0',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image lfiiyej2lh-editor_css',
              children:
                'https://coldchain.uzaiuzhai.com/coldchainimage/icon/more-app.png',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>冷库高度</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <p>冷库净高：3.6米/7.3米</p>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block1',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image lfij0fdaczn-editor_css',
              children:
                'https://coldchain.uzaiuzhai.com/coldchainimage/icon/more-app.png',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <p>承重</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>货架库5吨/平方米</p>
                          <p>平面库1吨/平方</p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block2',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image lfij1qmfgc-editor_css',
              children:
                'https://coldchain.uzaiuzhai.com/coldchainimage/icon/more-app.png',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>库型</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>3层或4层标准货架可选/平面库可选</p>
                        <p>
                          <br />
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block~lfgcsjqzem',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image lfij33cowuf-editor_css',
              children:
                'https://coldchain.uzaiuzhai.com/coldchainimage/icon/more-app.png',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <p>托盘规格</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            标准<span>托盘规格1200mm×1000mm</span>
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block3',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image lfij3gutw6o-editor_css',
              children:
                'https://coldchain.uzaiuzhai.com/coldchainimage/icon/more-app.png',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          <span>低温穿堂（月台）</span>
                          <br />
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        特别设计带有抽湿功能，独立制冷系统，能按需求保持穿堂干燥，使作业更洁净、卫生、安全
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block6',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image lfij3y3ps9k-editor_css',
              children:
                'https://coldchain.uzaiuzhai.com/coldchainimage/icon/more-app.png',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <p>制冷温度</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>冷冻库温度可达到-28℃，冷藏库温度0~10℃可控</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block5',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image lfij41vudja-editor_css',
              children:
                'https://coldchain.uzaiuzhai.com/coldchainimage/icon/more-app.png',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>单温面积</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <p>500-800平方米</p>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block7',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image lfij47dt1w-editor_css',
              children:
                'https://coldchain.uzaiuzhai.com/coldchainimage/icon/more-app.png',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <p>安全保障</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>24小时×365天 360度全方位独立监控，报警装置完善</p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block4',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image lfij4c2hh-editor_css',
              children:
                'https://coldchain.uzaiuzhai.com/coldchainimage/icon/more-app.png',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <p>地面材质</p>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          金刚砂耐磨<br />
                        </p>
                        <p>
                          <br />
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block~lfgcsnjcvl',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image lfij4fuxem-editor_css',
              children:
                'https://coldchain.uzaiuzhai.com/coldchainimage/icon/more-app.png',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <p>专利技术</p>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        国家专利级的地面防结冰装置，确保作业安全、高效、节能、实用<br />
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block~lfgcsrsm3hb',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image lfij4ju7oh7-editor_css',
              children:
                'https://coldchain.uzaiuzhai.com/coldchainimage/icon/more-app.png',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <p>智能控温</p>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <p>
                        中央集中控制， 智能控温系统，精确控制温度 及系统节能运行<br />
                      </p>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
      {
        md: 6,
        xs: 24,
        name: 'block~lfgcsqjqvm',
        className: 'feature7-block',
        children: {
          className: 'feature7-block-group',
          children: [
            {
              name: 'image',
              className: 'feature7-block-image lfij4poysi-editor_css',
              children:
                'https://coldchain.uzaiuzhai.com/coldchainimage/icon/more-app.png',
            },
            {
              name: 'title',
              className: 'feature7-block-title',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>系统</p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
            {
              name: 'content',
              className: 'feature7-block-content',
              children: (
                <span>
                  <span>
                    <span>
                      <span>
                        <p>
                          <span>
                            WMS仓储管理系统：可完全满足客户多品类（SKU)、高频率、多频次服务需求
                          </span>
                        </p>
                      </span>
                    </span>
                  </span>
                </span>
              ),
            },
          ],
        },
      },
    ],
  },
};
export const Content130DataSource = {
  OverPack: {
    className: 'home-page-wrapper content13-wrapper lffu6wxwu6-editor_css',
    playScale: 0.3,
    always: false,
    replay: false,
    appear: false,
  },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>联系我们</p>
          </span>
        ),
        className: 'title-h1 lffu6ydqb4-editor_css',
      },
      {
        name: 'content',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <p>电话： 13609798848</p>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
        className: 'title-content lffu72vkpcj-editor_css',
      },
      {
        name: 'content~lfggq6evcy',
        className: 'lffu7g9fchb-editor_css',
        children: (
          <span>
            <span>
              <span>
                <span>
                  <span>
                    <span>
                      <span>
                        <span>
                          <p>
                            020 - 32282101&nbsp; &nbsp;/<span>
                              &nbsp; &nbsp;020 - 82248830
                            </span>
                          </p>
                        </span>
                      </span>
                    </span>
                  </span>
                </span>
              </span>
            </span>
          </span>
        ),
      },
      {
        name: 'content2',
        children: (
          <span>
            <p>邮箱：&nbsp;sales@hongda-steeltube.com</p>
          </span>
        ),
        className: 'title-content lffu7g9fchb-editor_css',
      },
      {
        name: 'content~lffuc3gc51',
        className: 'lffuc4gol2r-editor_css',
        children: (
          <span>
            <p>地址： 广东省广州市黄埔区宝丰路2号</p>
          </span>
        ),
      },
    ],
  },
};
export const Contact01DataSource = {
  wrapper: {
    className: 'home-page-wrapper content10-wrapper lffn8b5wo5t-editor_css',
  },
  Content: {
    className: 'icon-wrapper',
    children: {
      icon: {
        className: 'icon',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/zIUVomgdcKEKcnnQdOzw.svg',
        name: '主要图标',
      },
      iconShadow: {
        className: 'icon-shadow',
        children:
          'https://gw.alipayobjects.com/zos/rmsportal/WIePwurYppfVvDNASZRN.svg',
        name: '图标影阴',
      },
      url: { children: 'https://gaode.com/place/B0IG27BI4J', name: '跳转地址' },
      title: { children: '宏达冷链', name: '弹框标题' },
      content: { children: '广东省广州市黄埔区宝丰路2号', name: '弹框内容' },
    },
  },
};
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>©2023 Hongda Coldchain&nbsp;All Rights Reserved</span>
        &nbsp;&nbsp;&nbsp;<a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer" >粤ICP备2023024085号</a>
      </span>
    ),
  },
};
