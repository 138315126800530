/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from 'react';
import { enquireScreen } from 'enquire-js';
import scrollScreen from 'rc-scroll-anim/lib/ScrollScreen';
import Banner0 from './Banner0';
import Content4 from './Content4';
import Banner5 from './Banner5';
import Feature3 from './Feature3';
import Feature7 from './Feature7';
import Content13 from './Content13';
import Contact0 from './Contact0';
import Footer0 from './Footer0';

import {
  Banner01DataSource,
  Content41DataSource,
  Banner51DataSource,
  Feature30DataSource,
  Feature70DataSource,
  Content130DataSource,
  Contact01DataSource,
  Footer00DataSource,
} from './data.source';
import './less/antMotionStyle.less';

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});


export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }

  componentDidMount() {

    scrollScreen.init({ location: ['Banner0_1', 'Content4_1', 'Banner5_1', 'Feature3_0', 'Feature7_0', 'Content13_0', 'Contact0_1', 'Footer0_0'] });

    // 适配手机屏幕;
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });

  }

  render() {
    const children = [
      <Banner0
        id="Banner0_1"
        key="Banner0_1"
        dataSource={Banner01DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content4
        id="Content4_1"
        key="Content4_1"
        dataSource={Content41DataSource}
        isMobile={this.state.isMobile}
      />,
      <Banner5
        id="Banner5_1"
        key="Banner5_1"
        dataSource={Banner51DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature3
        id="Feature3_0"
        key="Feature3_0"
        dataSource={Feature30DataSource}
        isMobile={this.state.isMobile}
      />,
      <Feature7
        id="Feature7_0"
        key="Feature7_0"
        dataSource={Feature70DataSource}
        isMobile={this.state.isMobile}
      />,
      <Content13
        id="Content13_0"
        key="Content13_0"
        dataSource={Content130DataSource}
        isMobile={this.state.isMobile}
      />,
      <Contact0
        id="Contact0_1"
        key="Contact0_1"
        dataSource={Contact01DataSource}
        isMobile={this.state.isMobile}
      />,
      <Footer0
        id="Footer0_0"
        key="Footer0_0"
        dataSource={Footer00DataSource}
        isMobile={this.state.isMobile}
      />,
    ];
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {/* 如果不是 dva 2.0 替换成 {children} start */}
        {children}
        {/* 如果不是 dva 2.0 替换成 {children} end */}
      </div>
    );
  }
}
